const ServiceData= [
    {
      id: 1,
      cName: 'fas fa-code',
      title:'First Package',
      desc: `A collection of 6 magnet photos of your choice`,
      url:"https://wa.me/96181678136?text=hi,%20we%20will%20reply%20soon%20leave%20your%20message%20pleaseRespond",
      num:"1"
    },
    {
      id: 2,
      cName: 'fas fa-database',
      title:'Second Package',
      desc: `A collection of 12 magnet photos of your choice `,
      url:"https://wa.me/96181678136?text=hi,%20we%20will%20reply%20soon%20leave%20your%20message%20pleaseRespond",
      num:"2"
    },
    {
        id: 3,
        cName: 'fas fa-paper-plane',
        title:'Third Package',
        desc: `a collection of 18 magnet photos of your choice `,
        url:"https://wa.me/96181678136?text=hi,%20we%20will%20reply%20soon%20leave%20your%20message%20pleaseRespond", // /order
        num:"3"
        
      },
    
     
  ];
  export default ServiceData;