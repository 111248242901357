import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCdvftpFuYwv429qRoHEZlbjK43qJTqs6A",
    authDomain: "memoreeze-20d36.firebaseapp.com",
    databaseURL: "https://memoreeze-20d36-default-rtdb.firebaseio.com",
    projectId: "memoreeze-20d36",
    storageBucket: "memoreeze-20d36.appspot.com",
    messagingSenderId: "433688092725",
    appId: "1:433688092725:web:d63848aefb1ab1c176517c",
    measurementId: "G-K82GW85V4S"
   
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;