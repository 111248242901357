import React from 'react'
import Slider from '../HeroSlider/Component/Slider'
import ServiceContent from '../ServicePageContent/ServiceContent'
import "../Css/style.css"
import Review from '../Review/Review'
import delivery from '../Media/delivery.png'

function Home() {
  
 
  return (
    <>
      <Slider />
      <section className="services">
        <ServiceContent />
      </section>   
      <section className="team">
        <div className="team-section-container">
          <div className="row-team">
            <div className="team-column team-col">
              <section className="home-page-team-content">
                <h1 className="team-title" >Ordering And Delivery</h1>
                <p className="team-para"> 
                  After you confirm the order items are prepared, and delivered within 3-5 working days.
                  We deliver all over lebanon and you pay cash on delivery.
                </p>
                        
              </section>
            </div>
            <div className="team-column">
              <img src={delivery} alt="" className="team-img" />
            </div>
          </div>
        </div>
      </section>
      <section className="homepage-review-section">
        <Review/>
      </section>
    
    </>
    
  );
}

export default Home;