import React from 'react'
import "../Css/style.css"
import { Link } from 'react-router-dom'
import './footer.css'
import ServiceData from '../ServicePageContent/ServiceData'
import SocialLinkData from './SocialLinkData.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';   
import { faInstagram } from '@fortawesome/free-brands-svg-icons';   
function Footer(props) {
  const currentYear = new Date().getFullYear();
    return (
        <>
                <footer>
        <div className ="content">
          <div className="left box">
            <div className="upper">
              <div className="topic" >About us</div>
              <p className="footer-about-para">small business owner who turns your best memories into magnets with best quality.</p>
            </div>
            <div className="lower">
              <div className="topic">Contact us</div>
              <div className="phone">
                <Link to="https://wa.me/96181678136?text=hi,%20we%20will%20reply%20soon%20leave%20your%20message%20pleaseRespond" className="footer-links"><i className="fas fa-phone-volume" />+96181678136</Link>
              </div>
              <div className="email">
                <Link to="mailto:Memoreeze@hotmail.com" className="footer-links" ><i className="fas fa-envelope" />Memoreeze@hotmail.com</Link>
              </div>
            </div>
          </div>
          <div className="middle box">
                        <div className="topic">Our Product</div>
                        {
                            ServiceData.map((item, index) => {
                                return (            
                                    <div key={index}>
                                        <Link to='' className="footer-links" >{item.title}</Link>
                                    </div>  
                                        )
                                        })
                                    }
                     </div>
          <div className="right box">
           

            <div className="newsletter">
                    <div id="mc_embed_signup" >
        <form action="https://gmail.us10.list-manage.com/subscribe/post?u=dbcccf1a1392e291d94b269b1&id=e4603c4d27" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
          <div id="mc_embed_signup_scroll">
            <div className="container" style={{alignItems: 'left', marginBottom: '20px'}}>
            <div className="topic">Send us your review</div>
            </div>
            <div className="mc-field-group" style={{}}>
              <input type="text"  className="required name newsletter-css" id="mce-NAME" placeholder="Name" />
            </div>
            <div className="mc-field-group" style={{}}>
              <input type="email"  className="required email newsletter-css" id="mce-EMAIL" placeholder="Email" 
              style={{
                height: "45px",
                width: "100%",
                outline: "none",
                color: "#d9d9d9",
                background: "#000",
                borderRadius: "5px",
                paddingLeft: "10px",
                fontSize: "17px",
                border: "2px solid #222222",
                marginTop:"10px"
              }}
              
              
              />
            </div>
            <div className="mc-field-group" style={{}}>
              <input type="text"  className="required name newsletter-css" id="mce-review" placeholder="review"
                style={{
                  height: "45px",
                  width: "100%",
                  outline: "none",
                  color: "#d9d9d9",
                  background: "#000",
                  borderRadius: "5px",
                  paddingLeft: "10px",
                  fontSize: "17px",
                  border: "2px solid #222222",
                  marginTop:"10px"
                }} />
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" />
              <div className="response" id="mce-success-response"  />
            </div>    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_dbcccf1a1392e291d94b269b1_e4603c4d27" tabIndex={-1} defaultValue /></div>
            <div className="clear" style={{}}><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button newsletter-btn" style={{
            
        }} /></div>
          </div>

          <div className="media-icons">
            <Link to='https://www.facebook.com/profile.php?id=61563484842115' className="soical-links" >
              <FontAwesomeIcon icon={faFacebook} size='lg' />
            </Link>
            <Link to='https://www.instagram.com/memoreeze_lb/' className="soical-links" >
              <FontAwesomeIcon icon={faInstagram} size='lg' />
            </Link>
</div>

        </form>
      </div>
    </div>






        </div>
    </div>
        <div className="bottom">
          <p>Copyright ©{currentYear} <Link to="https://www.memoreezelb.com">Memoreeze</Link> </p>
        </div>
      </footer>
        </>
    )
}
export default Footer;