import React,{Component} from 'react'
import { MenuItems } from "./MenuItems"
import memologo from '../Media/logo.png';
import "./Navbar.css"
import {Link } from 'react-router-dom';
//import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';   



class Navbar extends Component {
    constructor(){
        super()
        this.state={
            clicked:false
        }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
   
    render() {
        return(
            <>
              {/* <FloatingWhatsApp
                phoneNumber="123456789"
                accountName="Memoreeze"
                chatMessage="Welcome, How can we help?"
                allowEsc
                allowClickAway
                notification
                notificationSound
                avatar={memologo}
                /> */}
                <a href="https://wa.me/96181678136?text=hi,%20we%20will%20reply%20soon%20leave%20your%20message%20please
                    Respond" class="float" target="_blank">
                    <i class="my-float"><FontAwesomeIcon icon={faWhatsapp} size="lg" /></i>
                </a>
              <nav className="NavbarItems">
                <img className="memo-logo" src={memologo} alt="" />   
                <h1 className="navbar-logo">Memoreeze </h1>
                {/* <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link className={item.cName} to={item.url}>
                                {item.title}
                                </Link>
                            </li>
                        )
                    })}
                </ul> */}
            
            </nav>
            {/* <nav className="NavbarItems">
                <div> <img className="navbar-logo" src={memologo} alt="" /> </div>
               
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link className={item.cName} to={item.url}>
                                {item.title}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                 */}
            
            </>
        )
    }
}

export default Navbar


{/* <nav>
<div> <img className="navbar-logo" src={memologo} alt="" /> </div>

<div className="NavbarItems">
    <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
        {MenuItems.map((item, index) => {
            return (
                <li key={index}>
                    <Link className={item.cName} to={item.url}>
                    {item.title}
                    </Link>
                </li>
            )
        })}
    </ul>
</div>

</nav> */}