const ReviewsApi = [
    {
      id: 1,
      name: 'Hala Chebaro',
      job: 'Web Developer',
      image:
        'heart',
      text:
        "I'm vary happy for my order ,Best of luck dear 😍 ",
    },
    {
      id: 2,
      name: 'Rayane',
      job: 'Web Designer',
      image:
        'heart',
      text:
        'Merci ktiiir 3l order ktiiiiir hbyton w exactly mtl ma shfton 3l page w high quality 💓',
    },

  ];
  
  export default ReviewsApi;