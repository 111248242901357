import SliderOne from '../Media/slider1.png'
import SliderTwo from '../Media/slider2.png'
const sliderData= [
  {
    title: "",
    description: "",
    urls: SliderOne,
  },
  // {
  //   title: "",
  //   description: "",
  //   urls: SliderTwo,
  // },
  // {
  //   title: "INNOVATION",
  //   description: "We turn your memories into magnet",
  //   urls: SliderOne,
  // },
  // {
  //   title: "SPECIALIZE",
  //   description: "all your occasions memorable forever",
  //   urls: SliderTwo,
  // },
];
export default sliderData