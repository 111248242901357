import React from 'react'
import {Routes,Route } from 'react-router-dom';
import Home from '../Pages/Home'
import Contact from '../Pages/Contact'
import Services from '../Pages/Services'
import OrderForm from '../OrderForm/OrderForm';



function AppRouter(){
  return(
    <>
    <Routes>
    <Route path="/" >
      <Route index  element={<Home />}/>
      <Route path="contact" element={<Contact />}/>
      <Route path="services" element={<Services />}/>
      {/* <Route path="order" element={<OrderForm />}/> */}
      </Route>
    </Routes>
    </>
  )
}
export default AppRouter;
